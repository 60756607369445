import AppNavDrawer from "../../ui/AppNavDrawer/AppNavDrawer";
import AppNavDrawerProvider from "../../ui/AppNavDrawer/AppNavDrawerProvider";

interface RootLayoutProps {
  children?: React.ReactNode;
}

const RootLayout = ({ children }: RootLayoutProps) => {
  return (
    <AppNavDrawerProvider>
      <>
        <AppNavDrawer />
        {children}
      </>
    </AppNavDrawerProvider>
  );
};

export default RootLayout;
