import { ChipGroup, InputChip } from "@kablamooss/geo-ds-core-components";

type ProjectStatusFilter = "true" | "false";

const projectStatusFilterMap = new Map<ProjectStatusFilter, boolean>([
  ["true", true],
  ["false", false],
]);

interface ProjectsStatusToggleProps {
  onStatusChange: (status: boolean) => void;
  status: boolean;
}

const ProjectsStatusToggle = ({
  onStatusChange,
  status,
}: ProjectsStatusToggleProps) => {
  const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.currentTarget;
    onStatusChange(
      projectStatusFilterMap.get(value as ProjectStatusFilter) as boolean,
    );
  };

  return (
    <ChipGroup>
      <InputChip
        checked={status === false}
        id="current"
        name="project-status-toggle"
        onChange={onChange}
        type="radio"
        value="false"
      >
        Active
      </InputChip>
      <InputChip
        checked={status === true}
        id="archived"
        name="project-status-toggle"
        onChange={onChange}
        type="radio"
        value="true"
      >
        Archived
      </InputChip>
    </ChipGroup>
  );
};

export default ProjectsStatusToggle;
