export const ValueOrEmDash = ({
  value,
}: {
  value: string | number | undefined | null;
}) => {
  if (value === null || value === undefined || value === "") {
    return <>&mdash;</>;
  }
  return <>{value}</>;
};
