import AppNavHeader from "../../ui/AppNavHeader/AppNavHeader";
import AppLayout from "../AppLayout/AppLayout";

interface RiskModellingLayoutProps {
  children: React.ReactNode;
  subheader?: React.ReactNode;
  title: string;
}

const RiskModellingLayout = ({
  children,
  subheader,
  title,
}: RiskModellingLayoutProps) => {
  return (
    <AppLayout header={<AppNavHeader title={title} />} subheader={subheader}>
      {children}
    </AppLayout>
  );
};

export default RiskModellingLayout;
