import {
  Alert,
  ConfirmModal,
  ModalForm,
  onPromise,
  type AlertContent,
  showToast,
} from "@kablamooss/geo-ds-core-components";
import { useQueryClient } from "@tanstack/react-query";
import { isAxiosError } from "axios";
import { useRouter } from "next/router";
import { useEffect, useState } from "react";
import { FormProvider, useForm, type SubmitHandler } from "react-hook-form";
import {
  getGetProjectsQueryKey,
  usePostDryrunProjects,
  usePostProjects,
} from "../../../.rest-hooks/projects";
import {
  ServiceProjectTypeAttribute,
  type JSONAPIErrorResponse,
  type ServiceCreateProjectInput,
} from "../../../.rest-hooks/types";
import { DEFAULT_RMP_ERROR_MESSAGE } from "../../../config/constants";
import type { ProjectCategory } from "../../../types";
import assertNever from "../../../utils/assertNever/assertNever";
import CreateProjectForm, {
  getDefaultValues,
  type CreateProjectFormValues,
} from "./CreateProjectForm";
import CreateProjectFormSummary from "./CreateProjectFormSummary";

type ViewState =
  | {
      view: "edit";
    }
  | {
      values: CreateProjectFormValues;
      view: "review";
    };

interface CreateProjectModalProps {
  isOpen: boolean;
  onClose: () => void;
  projectCategory: ProjectCategory;
}

const CreateProjectModal = ({
  isOpen,
  onClose,
  projectCategory,
}: CreateProjectModalProps) => {
  const router = useRouter();
  const queryClient = useQueryClient();

  const [viewState, setViewState] = useState<ViewState>({ view: "edit" });
  const [userError, setUserError] = useState<AlertContent | null>(null);
  const [formError, setFormError] = useState<AlertContent | null>(null);

  const form = useForm<CreateProjectFormValues>({
    defaultValues: getDefaultValues(projectCategory),
  });

  const {
    formState: { isSubmitting },
    handleSubmit,
    reset,
  } = form;

  useEffect(() => {
    reset(getDefaultValues(projectCategory));
  }, [isOpen, projectCategory, reset]);

  const getSubmitData = (
    values: CreateProjectFormValues,
  ): ServiceCreateProjectInput => {
    const projectType = values.type!.value;

    switch (projectType) {
      case ServiceProjectTypeAttribute.bfrmp:
        return {
          bfmcs: values.bfmcs.map((bfmc) => bfmc.value),
          notes: values.notes,
          type: "bfrmp",
        };

      case ServiceProjectTypeAttribute["statewide-maxfuels"]:
      case ServiceProjectTypeAttribute["statewide-snapshot"]:
        return {
          notes: values.notes,
          type: projectType,
        };

      case ServiceProjectTypeAttribute["annual-works-plan-forecast"]:
      case ServiceProjectTypeAttribute["annual-works-plan-evaluation"]:
      case ServiceProjectTypeAttribute["individual-treatment-comparison"]:
      case ServiceProjectTypeAttribute[
        "individual-treatment-comparison-brigade"
      ]:
      case ServiceProjectTypeAttribute["modelled-impact-time"]:
        return {
          description: values.description,
          notes: values.notes,
          type: projectType,
        };

      default:
        return assertNever(projectType);
    }
  };

  const { mutateAsync: dryRun } = usePostDryrunProjects();

  const onSubmit: SubmitHandler<CreateProjectFormValues> = async (values) => {
    setFormError(null);

    try {
      const response = await dryRun({ data: getSubmitData(values) });

      setViewState({
        values: { ...values, name: response.data.data.attributes.name },
        view: "review",
      });

      setUserError(null);
    } catch (error) {
      if (
        isAxiosError<JSONAPIErrorResponse>(error) &&
        error.response?.data?.errors?.[0]?.code === "brg-project-limit-reached"
      ) {
        setUserError({
          title: "Project limit reached for this year",
          message:
            "Your project limit will reset on the 01/07. If you have additional modelling requirements, contact your district.",
        });
      } else {
        setFormError({
          message: "Try again.",
          title: "Unable to create project",
        });
      }
    }
  };

  const { mutateAsync: createProject } = usePostProjects({
    mutation: {
      onSettled: () => {
        void queryClient.invalidateQueries({
          queryKey: getGetProjectsQueryKey(),
        });
      },
      onSuccess: (data) => {
        const projectId = data.data.data.id;
        const projectType = data.data.data.attributes.type;

        switch (projectType) {
          case ServiceProjectTypeAttribute.bfrmp:
            void router.push(`/project/${projectId}/project-setup`);
            break;

          case ServiceProjectTypeAttribute["statewide-maxfuels"]:
            void router.push(`/statewide/${projectId}/scenarios`);
            break;

          case ServiceProjectTypeAttribute["statewide-snapshot"]:
          case ServiceProjectTypeAttribute["annual-works-plan-evaluation"]:
          case ServiceProjectTypeAttribute["annual-works-plan-forecast"]:
            void router.push(`/statewide/${projectId}/project-setup`);
            break;

          case ServiceProjectTypeAttribute["individual-treatment-comparison"]:
          case ServiceProjectTypeAttribute[
            "individual-treatment-comparison-brigade"
          ]:
          case ServiceProjectTypeAttribute["modelled-impact-time"]:
            void router.push(`/local/${projectId}/project-setup`);
            break;

          default:
            break;
        }

        showToast({
          title: "Project created",
          variant: "success",
        });
      },
      onError: () => {
        showToast({
          message: DEFAULT_RMP_ERROR_MESSAGE,
          title: "Unable to create project",
          variant: "error",
        });
      },
    },
  });

  const onComplete = async (values: CreateProjectFormValues) => {
    await createProject({ data: getSubmitData(values) });
  };

  const onConfirm = () => {
    if (viewState.view !== "review") {
      throw new Error("Cannot call onConfirm outside of review step");
    }

    return onComplete(viewState.values);
  };

  const onBack = () => {
    setViewState({ view: "edit" });
  };

  const onCompleteSuccess = () => {
    setViewState({ view: "edit" });
    onClose();
  };

  const onCancel = () => {
    setFormError(null);
    onClose();
  };

  return (
    <>
      <FormProvider {...form}>
        <ModalForm
          cancelLabel="Cancel"
          error={
            formError && (
              <Alert title={formError.title} variant="error">
                {formError.message}
              </Alert>
            )
          }
          id="create-project"
          isOpen={viewState?.view === "edit" && isOpen}
          isSubmitting={isSubmitting}
          onCancel={onCancel}
          onSubmit={onPromise(handleSubmit(onSubmit))}
          submitLabel="Next"
          title={`Add ${projectCategory} project details`}
        >
          <CreateProjectForm
            projectCategory={projectCategory}
            userError={userError}
          />
        </ModalForm>
      </FormProvider>
      <ConfirmModal
        cancelLabel="Back"
        confirmLabel="Create project"
        error={
          <Alert title="Unable to create a new project" variant="error">
            Please review the project and try again.
          </Alert>
        }
        isOpen={viewState.view === "review"}
        onConfirm={onConfirm}
        onConfirmSuccess={onCompleteSuccess}
        onCancel={onBack}
        title={`Review your ${projectCategory} project`}
      >
        {viewState.view === "review" && (
          <CreateProjectFormSummary project={viewState.values} />
        )}
      </ConfirmModal>
    </>
  );
};

export default CreateProjectModal;
