import { media } from "@kablamooss/geo-ds-core-components";
import styled from "styled-components";

type PageLayoutVariant = "default" | "weak" | "medium";

interface StyledPageLayoutProps {
  variant: PageLayoutVariant;
}

const StyledPageLayout = styled.div<StyledPageLayoutProps>`
  display: flex;
  padding: 1rem;
  flex: 1;
  flex-direction: column;
  background-color: ${(p) => {
    switch (p.variant) {
      case "weak": {
        return p.theme.colors.neutrals.backgroundWeak;
      }
      case "medium": {
        return p.theme.colors.neutrals.backgroundMedium;
      }
      default: {
        return p.theme.colors.neutrals.background;
      }
    }
  }};

  @media ${media.lg} {
    padding: 1.5rem;
    width: 100%;
    max-width: 120rem;
    margin: 0 auto;
    border: 1px solid ${(p) => p.theme.colors.neutrals.surfaceBorder};
    border-top: none;
    border-bottom: none;
  }
`;

interface PageLayoutProps {
  children?: React.ReactNode;
  variant?: PageLayoutVariant;
}

const PageLayout = ({ children, variant = "default" }: PageLayoutProps) => {
  return <StyledPageLayout variant={variant}>{children}</StyledPageLayout>;
};

export default PageLayout;
