import { Alert, FieldGrid } from "@kablamooss/geo-ds-core-components";
import styled from "styled-components";
import { ValueOrEmDash } from "../../util/ValueOrEmdash";
import type { CreateProjectFormValues } from "./CreateProjectForm";

const StyledCreateProjectFormSummary = styled.div`
  display: grid;
  gap: 1rem;
  padding-top: 0.5rem;
`;

const StyledParagraph = styled.div`
  overflow-wrap: break-word;
`;

interface CreateProjectFormSummaryProps {
  project: CreateProjectFormValues;
}

const CreateProjectFormSummary = ({
  project,
}: CreateProjectFormSummaryProps) => {
  const { bfmcs, description, name, notes, type } = project;

  const allBFMCs = bfmcs.map((bfmc) => bfmc.label);

  return (
    <StyledCreateProjectFormSummary>
      {type?.value === "individual-treatment-comparison-brigade" && (
        <Alert
          title="Once you create your project, it will count towards your two-project limit. If your project is rejected, it will no longer count toward your limit."
          variant="info"
        />
      )}
      <FieldGrid>
        {type?.value && type.value !== "bfrmp" && (
          <FieldGrid.Item label="Type of project">{type?.label}</FieldGrid.Item>
        )}
        <FieldGrid.Item label="Project code">{name}</FieldGrid.Item>
        {type?.value === "bfrmp" && (
          <FieldGrid.Item label="BFMC(s)">{allBFMCs.join(", ")}</FieldGrid.Item>
        )}
        {(type?.value === "modelled-impact-time" ||
          type?.value === "individual-treatment-comparison" ||
          type?.value === "individual-treatment-comparison-brigade") && (
          <FieldGrid.Item label="Description">
            <StyledParagraph>{description}</StyledParagraph>
          </FieldGrid.Item>
        )}
        <FieldGrid.Item label="Notes">
          <StyledParagraph>
            <ValueOrEmDash value={notes} />
          </StyledParagraph>
        </FieldGrid.Item>
      </FieldGrid>
    </StyledCreateProjectFormSummary>
  );
};

export default CreateProjectFormSummary;
