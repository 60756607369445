import { Button } from "@kablamooss/geo-ds-core-components";
import { useBoolean } from "usehooks-ts";
import { useHasCreateProjectPrivilege } from "../../../hooks/useHasCreateProjectPrivilege";
import {
  getProjectTypesForCategory,
  type ProjectCategory,
} from "../../../types";
import CreateProjectModal from "./CreateProjectModal";

interface CreateProjectButtonProps {
  disabled?: boolean;
  projectCategory: ProjectCategory;
}

const CreateProjectButton = ({
  disabled,
  projectCategory,
}: CreateProjectButtonProps) => {
  const { setTrue: open, setFalse: close, value: isOpen } = useBoolean();

  const query = useHasCreateProjectPrivilege();
  const projectTypes = getProjectTypesForCategory(projectCategory);
  const hasWritePrivilege = projectTypes.some((type) => query.data?.[type]);

  return (
    <>
      <Button
        disabled={disabled || !hasWritePrivilege}
        onClick={open}
        size="md"
        fullWidth
      >
        Create project
      </Button>
      <CreateProjectModal
        isOpen={isOpen}
        onClose={close}
        projectCategory={projectCategory}
      />
    </>
  );
};

export default CreateProjectButton;
