import type { ColumnSort, SortingState } from "@tanstack/react-table";

interface UseSortingParams<T extends string> {
  sortBy?: T;
  sortingRules: Record<T, ColumnSort>;
}

export const useSorting = <T extends string>({
  sortBy,
  sortingRules,
}: UseSortingParams<T>): SortingState | undefined => {
  return !!sortBy && sortBy in sortingRules
    ? [sortingRules[sortBy]]
    : undefined;
};
