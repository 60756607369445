import Link from "next/link";
import { ServiceProjectTypeAttribute } from "../../../.rest-hooks/types";
import { ProjectCategory } from "../../../types";
import assertNever from "../../../utils/assertNever/assertNever";
import RunTag from "../RunTag/RunTag";

interface ProjectCodeLinkButtonProps {
  "data-testid"?: string;
  projectCode: string;
  projectId: string;
  projectCategory: ProjectCategory;
  projectType: ServiceProjectTypeAttribute;
}

const ProjectCodeLinkButton = ({
  "data-testid": dataTestId,
  projectCode,
  projectId,
  projectCategory,
  projectType,
}: ProjectCodeLinkButtonProps) => {
  let path: string;
  let page: string;

  switch (projectCategory) {
    case ProjectCategory.STATEWIDE:
      path = "statewide";
      break;
    case ProjectCategory.LOCAL:
      path = "local";
      break;
    case ProjectCategory.BFRMP:
    default:
      path = "project";
  }

  switch (projectType) {
    case ServiceProjectTypeAttribute["individual-treatment-comparison"]:
    case ServiceProjectTypeAttribute["individual-treatment-comparison-brigade"]:
      page = "treatments";
      break;
    case ServiceProjectTypeAttribute.bfrmp:
    case ServiceProjectTypeAttribute["statewide-maxfuels"]:
    case ServiceProjectTypeAttribute["statewide-snapshot"]:
    case ServiceProjectTypeAttribute["modelled-impact-time"]:
    case ServiceProjectTypeAttribute["annual-works-plan-forecast"]:
    case ServiceProjectTypeAttribute["annual-works-plan-evaluation"]:
      page = "scenarios";
      break;
    default:
      return assertNever(projectType);
  }

  // NOTE: We cannot do a permissions check here as these links appear in lists with many projects which would result in
  // too many concurrent network requests
  return (
    <Link href={`/${path}/${projectId}/${page}`}>
      <RunTag data-testid={dataTestId}>{projectCode}</RunTag>
    </Link>
  );
};

export default ProjectCodeLinkButton;
