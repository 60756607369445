import { useGetPermissions } from "../.rest-hooks/user-management";
import type { Project } from "../types";

export const useHasCreateProjectPrivilege = () => {
  return useGetPermissions({
    query: {
      select: (data) => {
        const { permissions } = data.data.meta;
        return {
          bfrmp: permissions.includes("create-project-bfrmp"),
          "individual-treatment-comparison": permissions.includes(
            "create-project-individual-treatment-comparison",
          ),
          "individual-treatment-comparison-brigade": permissions.includes(
            "create-project-individual-treatment-comparison-brigade",
          ),
          "modelled-impact-time": permissions.includes(
            "create-project-modelled-impact-time",
          ),
          "statewide-maxfuels": permissions.includes(
            "create-project-statewide-maxfuels",
          ),
          "annual-works-plan-forecast": permissions.includes(
            "create-project-annual-works-plan-forecast",
          ),
          "annual-works-plan-evaluation": permissions.includes(
            "create-project-annual-works-plan-evaluation",
          ),
          "statewide-snapshot": permissions.includes(
            "create-project-statewide-snapshot",
          ),
        } satisfies Record<Project["type"], boolean>;
      },
    },
  });
};
