/**
 * Generated by orval v7.3.0 🍺
 * Do not edit manually.
 * Risk Modelling Platform API
 * The API for the risk modelling platform
 * OpenAPI spec version: 1.0
 */
import {
  useMutation,
  useQuery
} from '@tanstack/react-query'
import type {
  DataTag,
  DefinedInitialDataOptions,
  DefinedUseQueryResult,
  MutationFunction,
  QueryFunction,
  QueryKey,
  UndefinedInitialDataOptions,
  UseMutationOptions,
  UseMutationResult,
  UseQueryOptions,
  UseQueryResult
} from '@tanstack/react-query'
import axios from 'axios'
import type {
  AxiosError,
  AxiosRequestConfig,
  AxiosResponse
} from 'axios'
import qs from 'qs'
import type {
  AppBFMCResponse,
  AppBFMCsListResponse,
  GetBfmcsParams,
  JSONAPIErrorResponse,
  ServiceCreateBFMCInput,
  ServiceReplaceBFMCInput
} from './types'




/**
 * Get a list of BFMCs.
 * @summary Get a list of BFMCs
 */
export const getBfmcs = (
    params?: GetBfmcsParams, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<AppBFMCsListResponse>> => {
    
    return axios.get(
      `/bfmcs`,{
    ...options,
        params: {...params, ...options?.params},
        paramsSerializer: (params) => qs.stringify(params, {"arrayFormat":"comma"}),}
    );
  }


export const getGetBfmcsQueryKey = (params?: GetBfmcsParams,) => {
    return [`/bfmcs`, ...(params ? [params]: [])] as const;
    }

    
export const getGetBfmcsQueryOptions = <TData = Awaited<ReturnType<typeof getBfmcs>>, TError = AxiosError<JSONAPIErrorResponse>>(params?: GetBfmcsParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getBfmcs>>, TError, TData>>, axios?: AxiosRequestConfig}
) => {

const {query: queryOptions, axios: axiosOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetBfmcsQueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getBfmcs>>> = ({ signal }) => getBfmcs(params, { signal, ...axiosOptions });

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getBfmcs>>, TError, TData> & { queryKey: DataTag<QueryKey, TData> }
}

export type GetBfmcsQueryResult = NonNullable<Awaited<ReturnType<typeof getBfmcs>>>
export type GetBfmcsQueryError = AxiosError<JSONAPIErrorResponse>


export function useGetBfmcs<TData = Awaited<ReturnType<typeof getBfmcs>>, TError = AxiosError<JSONAPIErrorResponse>>(
 params: undefined |  GetBfmcsParams, options: { query:Partial<UseQueryOptions<Awaited<ReturnType<typeof getBfmcs>>, TError, TData>> & Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof getBfmcs>>,
          TError,
          TData
        > , 'initialData'
      >, axios?: AxiosRequestConfig}

  ):  DefinedUseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> }
export function useGetBfmcs<TData = Awaited<ReturnType<typeof getBfmcs>>, TError = AxiosError<JSONAPIErrorResponse>>(
 params?: GetBfmcsParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getBfmcs>>, TError, TData>> & Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof getBfmcs>>,
          TError,
          TData
        > , 'initialData'
      >, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> }
export function useGetBfmcs<TData = Awaited<ReturnType<typeof getBfmcs>>, TError = AxiosError<JSONAPIErrorResponse>>(
 params?: GetBfmcsParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getBfmcs>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> }
/**
 * @summary Get a list of BFMCs
 */

export function useGetBfmcs<TData = Awaited<ReturnType<typeof getBfmcs>>, TError = AxiosError<JSONAPIErrorResponse>>(
 params?: GetBfmcsParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getBfmcs>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> } {

  const queryOptions = getGetBfmcsQueryOptions(params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



/**
 * Create a BFMC.
 * @summary Create a BFMC
 */
export const postBfmcs = (
    serviceCreateBFMCInput: ServiceCreateBFMCInput, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<AppBFMCResponse>> => {
    
    return axios.post(
      `/bfmcs`,
      serviceCreateBFMCInput,options
    );
  }



export const getPostBfmcsMutationOptions = <TError = AxiosError<JSONAPIErrorResponse>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postBfmcs>>, TError,{data: ServiceCreateBFMCInput}, TContext>, axios?: AxiosRequestConfig}
): UseMutationOptions<Awaited<ReturnType<typeof postBfmcs>>, TError,{data: ServiceCreateBFMCInput}, TContext> => {
const {mutation: mutationOptions, axios: axiosOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postBfmcs>>, {data: ServiceCreateBFMCInput}> = (props) => {
          const {data} = props ?? {};

          return  postBfmcs(data,axiosOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type PostBfmcsMutationResult = NonNullable<Awaited<ReturnType<typeof postBfmcs>>>
    export type PostBfmcsMutationBody = ServiceCreateBFMCInput
    export type PostBfmcsMutationError = AxiosError<JSONAPIErrorResponse>

    /**
 * @summary Create a BFMC
 */
export const usePostBfmcs = <TError = AxiosError<JSONAPIErrorResponse>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postBfmcs>>, TError,{data: ServiceCreateBFMCInput}, TContext>, axios?: AxiosRequestConfig}
): UseMutationResult<
        Awaited<ReturnType<typeof postBfmcs>>,
        TError,
        {data: ServiceCreateBFMCInput},
        TContext
      > => {

      const mutationOptions = getPostBfmcsMutationOptions(options);

      return useMutation(mutationOptions);
    }
    /**
 * Archive a specific BFMC.
 * @summary Archive a specific BFMC
 */
export const deleteBfmcsBfmcId = (
    bfmcId: string, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<AppBFMCResponse>> => {
    
    return axios.delete(
      `/bfmcs/${bfmcId}`,options
    );
  }



export const getDeleteBfmcsBfmcIdMutationOptions = <TError = AxiosError<JSONAPIErrorResponse>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteBfmcsBfmcId>>, TError,{bfmcId: string}, TContext>, axios?: AxiosRequestConfig}
): UseMutationOptions<Awaited<ReturnType<typeof deleteBfmcsBfmcId>>, TError,{bfmcId: string}, TContext> => {
const {mutation: mutationOptions, axios: axiosOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof deleteBfmcsBfmcId>>, {bfmcId: string}> = (props) => {
          const {bfmcId} = props ?? {};

          return  deleteBfmcsBfmcId(bfmcId,axiosOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type DeleteBfmcsBfmcIdMutationResult = NonNullable<Awaited<ReturnType<typeof deleteBfmcsBfmcId>>>
    
    export type DeleteBfmcsBfmcIdMutationError = AxiosError<JSONAPIErrorResponse>

    /**
 * @summary Archive a specific BFMC
 */
export const useDeleteBfmcsBfmcId = <TError = AxiosError<JSONAPIErrorResponse>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteBfmcsBfmcId>>, TError,{bfmcId: string}, TContext>, axios?: AxiosRequestConfig}
): UseMutationResult<
        Awaited<ReturnType<typeof deleteBfmcsBfmcId>>,
        TError,
        {bfmcId: string},
        TContext
      > => {

      const mutationOptions = getDeleteBfmcsBfmcIdMutationOptions(options);

      return useMutation(mutationOptions);
    }
    /**
 * Get a specific BFMC.
 * @summary Get a specific BFMC
 */
export const getBfmcsBfmcId = (
    bfmcId: string, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<AppBFMCResponse>> => {
    
    return axios.get(
      `/bfmcs/${bfmcId}`,options
    );
  }


export const getGetBfmcsBfmcIdQueryKey = (bfmcId: string,) => {
    return [`/bfmcs/${bfmcId}`] as const;
    }

    
export const getGetBfmcsBfmcIdQueryOptions = <TData = Awaited<ReturnType<typeof getBfmcsBfmcId>>, TError = AxiosError<JSONAPIErrorResponse>>(bfmcId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getBfmcsBfmcId>>, TError, TData>>, axios?: AxiosRequestConfig}
) => {

const {query: queryOptions, axios: axiosOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetBfmcsBfmcIdQueryKey(bfmcId);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getBfmcsBfmcId>>> = ({ signal }) => getBfmcsBfmcId(bfmcId, { signal, ...axiosOptions });

      

      

   return  { queryKey, queryFn, enabled: !!(bfmcId), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getBfmcsBfmcId>>, TError, TData> & { queryKey: DataTag<QueryKey, TData> }
}

export type GetBfmcsBfmcIdQueryResult = NonNullable<Awaited<ReturnType<typeof getBfmcsBfmcId>>>
export type GetBfmcsBfmcIdQueryError = AxiosError<JSONAPIErrorResponse>


export function useGetBfmcsBfmcId<TData = Awaited<ReturnType<typeof getBfmcsBfmcId>>, TError = AxiosError<JSONAPIErrorResponse>>(
 bfmcId: string, options: { query:Partial<UseQueryOptions<Awaited<ReturnType<typeof getBfmcsBfmcId>>, TError, TData>> & Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof getBfmcsBfmcId>>,
          TError,
          TData
        > , 'initialData'
      >, axios?: AxiosRequestConfig}

  ):  DefinedUseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> }
export function useGetBfmcsBfmcId<TData = Awaited<ReturnType<typeof getBfmcsBfmcId>>, TError = AxiosError<JSONAPIErrorResponse>>(
 bfmcId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getBfmcsBfmcId>>, TError, TData>> & Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof getBfmcsBfmcId>>,
          TError,
          TData
        > , 'initialData'
      >, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> }
export function useGetBfmcsBfmcId<TData = Awaited<ReturnType<typeof getBfmcsBfmcId>>, TError = AxiosError<JSONAPIErrorResponse>>(
 bfmcId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getBfmcsBfmcId>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> }
/**
 * @summary Get a specific BFMC
 */

export function useGetBfmcsBfmcId<TData = Awaited<ReturnType<typeof getBfmcsBfmcId>>, TError = AxiosError<JSONAPIErrorResponse>>(
 bfmcId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getBfmcsBfmcId>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> } {

  const queryOptions = getGetBfmcsBfmcIdQueryOptions(bfmcId,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



/**
 * Replace a specific BFMC.
 * @summary Replace a specific BFMC
 */
export const putBfmcsBfmcId = (
    bfmcId: string,
    serviceReplaceBFMCInput: ServiceReplaceBFMCInput, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<AppBFMCResponse>> => {
    
    return axios.put(
      `/bfmcs/${bfmcId}`,
      serviceReplaceBFMCInput,options
    );
  }



export const getPutBfmcsBfmcIdMutationOptions = <TError = AxiosError<JSONAPIErrorResponse>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof putBfmcsBfmcId>>, TError,{bfmcId: string;data: ServiceReplaceBFMCInput}, TContext>, axios?: AxiosRequestConfig}
): UseMutationOptions<Awaited<ReturnType<typeof putBfmcsBfmcId>>, TError,{bfmcId: string;data: ServiceReplaceBFMCInput}, TContext> => {
const {mutation: mutationOptions, axios: axiosOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof putBfmcsBfmcId>>, {bfmcId: string;data: ServiceReplaceBFMCInput}> = (props) => {
          const {bfmcId,data} = props ?? {};

          return  putBfmcsBfmcId(bfmcId,data,axiosOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type PutBfmcsBfmcIdMutationResult = NonNullable<Awaited<ReturnType<typeof putBfmcsBfmcId>>>
    export type PutBfmcsBfmcIdMutationBody = ServiceReplaceBFMCInput
    export type PutBfmcsBfmcIdMutationError = AxiosError<JSONAPIErrorResponse>

    /**
 * @summary Replace a specific BFMC
 */
export const usePutBfmcsBfmcId = <TError = AxiosError<JSONAPIErrorResponse>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof putBfmcsBfmcId>>, TError,{bfmcId: string;data: ServiceReplaceBFMCInput}, TContext>, axios?: AxiosRequestConfig}
): UseMutationResult<
        Awaited<ReturnType<typeof putBfmcsBfmcId>>,
        TError,
        {bfmcId: string;data: ServiceReplaceBFMCInput},
        TContext
      > => {

      const mutationOptions = getPutBfmcsBfmcIdMutationOptions(options);

      return useMutation(mutationOptions);
    }
    